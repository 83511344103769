import React, {useEffect, useRef} from 'react';
import Reveal from 'reveal.js';
import RevealNotes from 'reveal.js/plugin/notes/notes.esm';

const loadSlides = async (observer, container, urls) => {
    for (let url of urls) {
        const section = document.createElement('section')
        section.dataset.url = url

        container.appendChild(section)

        observer.observe(section, {attributes: true})
    }
};

const loadSlideContent = async (slide) => {
    const response = await fetch(slide.dataset.url)

    slide.innerHTML = await response.text()
    slide.removeAttribute('data-url')
}

const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const slide = mutation.target;

            if (slide.hasAttribute('data-url') &&
                    (slide.classList.contains('present') ||
                            ((slide.classList.contains('future') || slide.classList.contains('past')) &&
                                    slide.style.display === 'block')
                    )
            ) {
                (async () => { await loadSlideContent(slide)})()
            }
        }
    });
});

const Presentation = ({slides}) => {
    const container = useRef()

    useEffect(() => {
        if(!container.current) { return }

        (async () => {
            await loadSlides(observer, container.current, slides)

            Reveal.initialize({
                width: 1920,
                height: 1200,
                margin: 0,
                hash: true,
                respondToHashChanges: true,
                history: true,
                plugins: [RevealNotes]
            });
        })()

        return () => {
            Reveal.destroy();
        }
    }, [container])

    return <div ref={container} className="slides"/>
}

export default Presentation