import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { List, is } from 'immutable';
import FileTypeIcon from './FileTypeIcon';
import FileSizeFormatter from './FileSizeFormatter';
import axios from 'axios';
import accept from 'attr-accept';
import { DirectUpload } from '@rails/activestorage';

const UploadButton = ({accept, multiple, required, showSubmit, addFiles, buttonLabel, buttonSubmitLabel}) => {
    const inputElement = useRef()

    const handleClick = (e) => {

        e.preventDefault();
        inputElement.current.click();

    };

    if(inputElement.current) { inputElement.current.value = "" }

    return <>
        <input type='file' accept={accept} multiple={multiple} required={required} ref={inputElement}
               onChange={() => addFiles(inputElement.current.files)}
        />
        <button type='button' onClick={handleClick} className='btn btn-default'>{buttonLabel}</button>
        {showSubmit &&
                <button type='submit' onClick={handleClick} className='btn btn-primary'>{buttonSubmitLabel}</button>
        }
    </>
}

class RestoredFile extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            pendingRemoval: false
        }

    }

    handleClick = () => {
        this.setState({pendingRemoval: this.props.allowRemoval && !this.state.pendingRemoval})
    };

    handleRemoval = () => {

        this.setState({pendingRemoval: false}, async () => {

            const response = await axios.delete(`/attachments/${this.props.file.id}`,{
                headers : {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            });

            if(response.status === 204) {
                this.props.onClick();
            }

        });
    }

    render() {

        return (

            <li onClick={this.handleClick}>
                <div className={'content'}>
                    { this.state.pendingRemoval && <div className='file-type' onClick={this.handleRemoval}><i className='content-type-icon fa-light fa-trash text-danger' /></div> }
                    { !this.state.pendingRemoval &&
                    <div className='file-type'>
                        <FileTypeIcon type={this.props.file.type}/>
                    </div>
                    }
                    <p>
                        <span className='file-name'>{this.props.file.name}</span>
                        <span className='file-size'>
                            <FileSizeFormatter size={parseInt(this.props.file.size,10)}/>
                        </span>
                    </p>
                </div>
            </li>

        )

    }

}

RestoredFile.propTypes = {
    file: PropTypes.object,
    onClick: PropTypes.func,
    allowRemoval: PropTypes.bool
};

class DirectFile extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            pendingRemoval: false
        }

    }

    handleClick = () => {

        if(this.state.pendingRemoval) {
            this.setState({pendingRemoval: false})
        } else {
            this.setState({pendingRemoval: true});
        }

    };

    handleRemoval = () => {

        this.setState({pendingRemoval: false}, async () => {
            this.props.onClick();
        });

    }

    render() {

        const progress = this.props.file.uploadProgress

        let styles = {}

        if(progress > 0) {
            styles = {background: `linear-gradient(to right, rgba(136, 141, 168, 0.3) ${progress}%, white ${progress}%)`}
        }

        return (

            <li onClick={this.handleClick}>
                <div className={'content'}>
                    { this.state.pendingRemoval && <div className='file-type' onClick={this.handleRemoval}><i className='content-type-icon fa-light fa-trash text-danger' /></div> }
                    { !this.state.pendingRemoval &&
                        <div className='file-type'>
                            <FileTypeIcon type={this.props.file.type}/>
                        </div>
                    }
                    <p style={styles}>
                        <span className='file-name'>{this.props.file.name}</span>
                        <span className='file-size'>
                            <FileSizeFormatter loaded={this.props.file.loaded} size={this.props.file.size} />
                        </span>
                    </p>
                    <input type='hidden' name={`${this.props.inputName}`} defaultValue={this.props.file.blobId} />
                </div>
            </li>

        )

    }

}

DirectFile.propTypes = {
    file: PropTypes.object,
    onClick: PropTypes.func,
    inputName: PropTypes.string
};

class UploadFile extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            pendingRemoval: false
        }

    }

    handleClick = () => {

        if(this.state.pendingRemoval) {
            this.setState({pendingRemoval: false})
        } else {
            this.setState({pendingRemoval: true});
        }

    };

    render() {

        return (

            <li onClick={this.handleClick}>
                <div className={'content'}>
                    { this.state.pendingRemoval && <div className='file-type' onClick={this.props.onClick}><i className='content-type-icon fa-light fa-trash text-danger' /></div> }
                    { !this.state.pendingRemoval &&
                        <div className='file-type'>
                            <FileTypeIcon type={this.props.file.type}/>
                        </div>
                    }
                    <p>
                        <span className='file-name'>{this.props.file.name}</span>
                        <span className='file-size'>
                            <FileSizeFormatter size={parseInt(this.props.file.size,10)} />
                        </span>
                    </p>
                    <input type='hidden' name={`${this.props.inputName}[data]`} value={this.props.file.data} />
                    <input type='hidden' name={`${this.props.inputName}[name]`} value={this.props.file.name} />
                    <input type='hidden' name={`${this.props.inputName}[size]`} value={this.props.file.size} />
                    <input type='hidden' name={`${this.props.inputName}[type]`} value={this.props.file.type} />
                </div>
            </li>

        )

    }

}

UploadFile.propTypes = {
    file: PropTypes.object,
    inputName: PropTypes.string,
    onClick: PropTypes.func
};

class FileList extends React.PureComponent {

    render() {

        if(this.props.files.size === 0) {
            return <p>{this.props.description}</p>;
        }

        return (
            <ul>
                {
                    this.props.files.map((file, idx) =>
                        {

                            if(Object.prototype.hasOwnProperty.call(file, 'data')) {
                                return <UploadFile key={idx} inputName={this.props.inputName} file={file} onClick={() => this.props.removeFile(idx)} />
                            } else if(Object.prototype.hasOwnProperty.call(file, 'source')) {
                                return <DirectFile key={idx} inputName={this.props.inputName} file={file} onClick={() => this.props.removeFile(idx)} />
                            } else {
                                return <RestoredFile key={idx} file={file} allowRemoval={this.props.allowRemoval} onClick={() => this.props.removeFile(idx)} />
                            }

                        }
                    )
                }
            </ul>
        );

    }

}

FileList.propTypes = {
    inputName: PropTypes.string,
    description: PropTypes.string,
    files: PropTypes.instanceOf(List),
    removeFile: PropTypes.func,
    allowRemoval: PropTypes.bool
};

class DropZone extends React.PureComponent {

    domElement = React.createRef()
    form = null

    constructor(props) {

        super(props);

        this.state = {
            targeted: false,
            dragEvents: 0,
            allowed: true,
            files: List(this.props.files)
        };

    }

    componentDidMount() {

        if(!this.props.directUpload) {
            return;
        }

        this.form = this.domElement.closest('form');

        if('remote' in this.form.dataset) {

            // Prevent rails from submitting remote form
            this.form.addEventListener('ajax:before', this.preventRemoteSubmission);

        }

        this.form.addEventListener('submit', this.uploadFilesDirectly);

    }

    async componentDidUpdate(_prevProps, prevState) {

        if(
            this.props.directUpload &&
            this.form &&
            !is(prevState.files, this.state.files) &&
            this.state.files.size > 0 &&
            this.state.files.every(f => 'blobId' in f)
        ) {

           await this.triggerSubmit()

        }

    }

    componentWillUnmount() {

        if(!this.props.directUpload) {
            return
        }

        if('remote' in this.form.dataset) {
            this.form.removeEventListener('ajax:before', this.preventRemoteSubmission);
        }

        this.form.removeEventListener('submit', this.uploadFilesDirectly);

    }

    triggerSubmit = async () => {

        if('remote' in this.form.dataset) {
            await this.submitFormRemotely();
        } else {
            this.form.submit();
        }

    }

    preventRemoteSubmission = (e) => {
        e.preventDefault();
        return false;
    }

    submitFormRemotely = async () => {
        const result = await axios({
            method: this.form.method,
            url: this.form.action,
            data: new FormData(this.form),
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/javascript'
            }
        })

        if(result.status === 200) {
            const script = document.createElement('script');
            script.text = result.data;

            document.head.appendChild(script).parentNode.removeChild(script);
        }
    }

    uploadFilesDirectly = (e) => {
        if(this.state.files.size === 0) { return }

        e.preventDefault();
        this.state.files.forEach(this.directUpload)
    }

    directUpload = ({source: file}, idx, array) => {
        if(idx === 0) {
            window.onbeforeunload = () => "Es wird gerade eine Datei hochgeladen, diese Seite wirklich verlassen?"
        }

        let url = `${location.protocol}//${location.host}/storage/direct_uploads`

        if(this.props.directUploadUrl) {
            url = this.props.directUploadUrl
        }

        const upload = new DirectUpload(file, url, {
            directUploadWillStoreFileWithXHR: (request) => {
                request.upload.addEventListener('progress',
                    event => this.directUploadDidProgress(idx, event))
            }
        })

        upload.create((error, blob) => {

            if(array.size === idx + 1) {
                window.onbeforeunload = null
            }

            if(error) {
                alert('Upload fehlgeschlagen');
                return;
            }

            this.setState({
                files: this.state.files.set(idx, Object.assign({}, this.state.files.get(idx), {}, {blobId: blob.signed_id}))
            })

        })
    }

    directUploadDidProgress = (idx, event) => {
        if(!event.lengthComputable) {
            return
        }

        const file = Object.assign({}, this.state.files.get(idx), {loaded: event.loaded, uploadProgress: Math.ceil((event.loaded / event.total) * 100)})

        this.setState({files: this.state.files.set(idx, file)})
    }

    addFiles = async (files) => {
        if(!this.props.multiple && !this.state.files.isEmpty()) {
            return;
        }

        for(const [idx, file] of Array.from(files).entries()) {
            await this.processFile(file, idx)
        }
    };

    processFile = async (file, idx) => {
        if(this.props.maximumSize && file.size > this.props.maximumSize) {

            alert(this.props.maximumSizeLabel);
            return;

        }

        const { name, size, type } = file;
        const item = {name, size, type, loaded: 0, uploadProgress: 0}

        if(this.props.directUpload) {
            item['source'] = file
        }

        this.setState(state => ({files: state.files.insert(idx, item)}))

        if(!this.props.directUpload) {
            await this.readFile(file, idx)
        }
    }
    
    readFile = async (file, idx) => {

        const fileReader = new FileReader();

        fileReader.addEventListener('load', (e) => {

            const result = e.target.result;
            const item = Object.assign({}, this.state.files.get(idx), {data: result.substring(result.indexOf(',') + 1)})

            this.setState(
                (prevState) => ({files: prevState.files.set(idx, item)}),
                () => { if(this.props.onFileAdded) { this.props.onFileAdded(item) }
            });

        });

        await fileReader.readAsDataURL(file);
        
    }

    removeFile = (idx) => {
        if(this.props.disabled) {
            return
        }

        this.setState({files: this.state.files.delete(idx)}, this.props.onFileRemoved);
    };

    handleDragStart = (e) => {
        e.dataTransfer.effectAllowed = 'copy';
    };

    handleDragEnter = (e) => {

        const {dataTransfer} = e
        const {items} = dataTransfer

        let allowed = true

        for (const item of items) {

            if(!accept({type: item.type}, this.props.accept)) {
                allowed = false
                break
            }

        }

        e.preventDefault();

        this.setState({
            targeted: true,
            allowed,
            dragEvents: this.state.dragEvents + 1
        });

    };

    handleDragOver = (e) => {

        e.preventDefault();

        if(this.state.allowed) {
            e.dataTransfer.dropEffect = 'copy';
        } else {
            e.dataTransfer.dropEffect = 'none';
        }

    };

    handleDragLeave = () => {
        
        const remainingEvents = this.state.dragEvents - 1;

        this.setState({
            targeted: remainingEvents > 0,
            dragEvents: remainingEvents
        });

    };

    handleDrop = async (e) => {

        e.preventDefault();

        if(this.state.allowed) {
            await this.addFiles(e.dataTransfer.files);
        }

        this.setState({targeted: false, allowed: true});

    };

    handleSubmit = (e) => {

        e.target.form.onsubmit = () => {
            e.target.toggleAttribute('disabled');
        }

    }

    render() {

        let dropZoneClass = 'drop-zone';

        if(this.state.targeted && this.state.allowed) {
            dropZoneClass = `${dropZoneClass} targeted`
        }

        const required = this.props.required && this.state.files.size === 0;

        return (

            <div
                ref={el => this.domElement = el}
                className={dropZoneClass}
                onDragStart={this.handleDragStart}
                onDragEnter={this.handleDragEnter}
                onDragOver={this.handleDragOver}
                onDragLeave={this.handleDragLeave}
                onDrop={this.handleDrop}
            >
                <FileList inputName={this.props.name} description={this.props.description} files={this.state.files} allowRemoval={!this.props.disabled} removeFile={this.removeFile} />
                <div>
                    { (!this.props.disabled && (this.state.files.size === 0 || this.props.multiple)) &&
                        <UploadButton accept={this.props.accept} inputName={this.props.name} multiple={this.props.multiple} required={required} buttonLabel={this.props.buttonLabel} addFiles={this.addFiles} />
                    }
                    {this.props.showSubmit && this.state.files.size > 0 &&
                        <button type='submit' className='btn btn-primary' onClick={this.handleSubmit}>{this.props.buttonSubmitLabel}</button>
                    }
                </div>
            </div>

        );

    }

}

DropZone.propTypes = {
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    showSubmit: PropTypes.bool.isRequired,
    buttonSubmitLabel: PropTypes.string,
    onFileAdded: PropTypes.func,
    onFileRemoved: PropTypes.func,
    files: PropTypes.array.isRequired,
    maximumSize: PropTypes.number.isRequired,
    maximumSizeLabel: PropTypes.string.isRequired,
    accept: PropTypes.string,
    directUpload: PropTypes.bool,
    directUploadUrl: PropTypes.string,
    disabled: PropTypes.bool
};

DropZone.defaultProps = {
    multiple: false,
    required: false,
    showSubmit: false,
    files: [],
    maximumSize: 0,
    maximumSizeLabel: '',
    directUpload: false,
    disabled: false
}

export default DropZone